import mynpmchat from './myNpmChat/MyNpmChat.vue'

import { startDrag } from './myNpmChat/drag.js'
const draggable = (el, binding) => {
  startDrag(el.querySelector('.el-dialog__header'), el.querySelector('.el-dialog'), binding.value);
}

const coms =[mynpmchat];
const install = function(Vue){
  coms.forEach((com)=>{
    Vue.component(com.name,com)
  })
  Vue.directive("draggable", draggable);
}
export default install